import { isNil } from 'ramda';

import { Product, Setting } from 'service/types';
import { FULFILLMENT_MODES, LOW_STOCK_MESSAGE_TYPE, PRODUCT_AVAILABILITY } from 'common/constants';
import { localizeNumber, normalizeFulfillmentMode } from 'common/utils';
import { Translate } from 'next-translate';

type GetAvailabilityTextAndColorType = ({
  stockCount,
  unavailable,
  trackingType,
  selectedMode,
  productAvailabilityMode,
  publishedInCurrentBranch,
  variantQuantity,
}: {
  stockCount: Product['stockData']['stockCount'];
  unavailable: Product['stockData']['unavailable'];
  selectedMode: string;
  trackingType: string;
  stockThresholdSettings?: Partial<Setting>;
  productAvailabilityMode: Product['fulfillmentMode'];
  theme?: any;
  publishedInCurrentBranch?: boolean;
  variantQuantity?: number;
}) => AvailabilityInfo;

export type AvailabilityInfo = {
  unavailabilityReason?: string;
  availabilityTagVariant?: string;
  availabilityTextColor?: string;
  isLowInStock?: boolean;
};

type isItemAvailableForCurrentModeType = (currentMode: string, menuItemFulfillmentMode: string) => boolean;

export const isItemAvailableForCurrentMode: isItemAvailableForCurrentModeType = (currentMode, menuItemFulfillmentMode) => {
  const normalizedMode = currentMode && normalizeFulfillmentMode(currentMode);
  const isProductModeAvailable =
    !currentMode ||
    menuItemFulfillmentMode === FULFILLMENT_MODES.DELIVERY_AND_PICKUP ||
    menuItemFulfillmentMode === normalizedMode ||
    (menuItemFulfillmentMode === FULFILLMENT_MODES.PICKUP && normalizedMode === FULFILLMENT_MODES.BEACH);
  return isProductModeAvailable;
};

export const getAvailabilityTypeAttributes: GetAvailabilityTextAndColorType = ({
  stockCount,
  unavailable,
  selectedMode,
  stockThresholdSettings,
  productAvailabilityMode,
  trackingType,
  theme,
  publishedInCurrentBranch,
  variantQuantity,
}) => {
  // TODO split this utility function to two functions one for availability and one for stock and use availability
  //  function in checkout page
  const { lowStockThreshold } = stockThresholdSettings || {};

  const isProductModeAvailable = isItemAvailableForCurrentMode(selectedMode, productAvailabilityMode);

  if (publishedInCurrentBranch === false) {
    return {
      unavailabilityReason: PRODUCT_AVAILABILITY.NOT_PUBLISHED,
      availabilityTagVariant: 'secondary',
      ...(theme && { availabilityTextColor: theme.color.danger }),
    };
  }
  if (unavailable || (trackingType && stockCount === 0)) {
    return {
      unavailabilityReason: PRODUCT_AVAILABILITY.VARIANT_UNAVAILABLE,
      availabilityTagVariant: 'secondary',
      ...(theme && { availabilityTextColor: theme.color.danger }),
    };
  }
  if (trackingType && stockCount < variantQuantity) {
    return {
      unavailabilityReason: PRODUCT_AVAILABILITY.EXCEEDS_STOCK_COUNT,
    };
  }
  if (!isNil(productAvailabilityMode) && !isProductModeAvailable)
    return {
      unavailabilityReason: PRODUCT_AVAILABILITY.NOT_MODE_AVAILABLE,
      availabilityTagVariant: 'secondary',
      ...(theme && { availabilityTextColor: theme.color.danger }),
    };
  if (trackingType && !!stockCount && stockCount <= lowStockThreshold)
    return {
      isLowInStock: true,
      availabilityTagVariant: 'secondary',
      ...(theme && { availabilityTextColor: theme.color.headlineText }),
    };
  return {};
};

type TranslateProductAvailabilityType = ({
  t,
  mode,
  stockCount,
  trackingType,
  unavailabilityReason,
  isLowInStock,
  lang,
}: {
  t: Translate;
  mode?: string;
  stockCount: number;
  lowStockMessage: string;
  trackingType?: string | null;
  unavailabilityReason: string;
  isLowInStock: boolean;
  lang: string;
}) => string;

export const translateProductAvailability: TranslateProductAvailabilityType = ({
  t,
  mode,
  stockCount,
  lowStockMessage,
  trackingType,
  unavailabilityReason,
  isLowInStock,
  lang,
}) => {
  if (!unavailabilityReason && !isLowInStock) return null;
  // eslint-disable-next-line default-case
  switch (unavailabilityReason) {
    case PRODUCT_AVAILABILITY.NOT_PUBLISHED:
      return t('notAvailable');
    case PRODUCT_AVAILABILITY.VARIANT_UNAVAILABLE:
      return t('soldOut');
    case PRODUCT_AVAILABILITY.EXCEEDS_STOCK_COUNT:
      return `${t('maxOf')} ${localizeNumber(lang, stockCount)}`;
    case PRODUCT_AVAILABILITY.NOT_MODE_AVAILABLE:
      switch (mode) {
        case FULFILLMENT_MODES.DELIVERY:
          return t('availableForPickup');
        case FULFILLMENT_MODES.PICKUP:
        case FULFILLMENT_MODES.CAR_PICKUP:
          return t('availableForDelivery');
        default:
          return null;
      }
  }
  switch (lowStockMessage) {
    case LOW_STOCK_MESSAGE_TYPE.LESS_THAN_MIN_LEFT:
      return `${t('only')} ${localizeNumber(lang, stockCount)} ${t('available')}`;
    case LOW_STOCK_MESSAGE_TYPE.FEW_ITEMS_LEFT:
      return `${t('fewItemsLeft')} `;
    default:
      return null;
  }
};
