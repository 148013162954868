import { gql } from 'graphql-request';

const stockThresholdSettingsQuery = gql`
  query stockThresholdSettings($subdomain: String!) {
    store(subdomain: $subdomain) {
      id
      setting {
        lowStockMessage
        lowStockThreshold
      }
    }
  }
`;

export default stockThresholdSettingsQuery;
