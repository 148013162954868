export enum PRODUCT_AVAILABILITY {
  NOT_PUBLISHED = 'notPublished',
  VARIANT_UNAVAILABLE = 'unavailable',
  NOT_MODE_AVAILABLE = 'notModeAvailable',
  EXCEEDS_STOCK_COUNT = 'exceedsStockCount',
}

export enum TRACKING_TYPE {
  STOCK = 'stock',
  DAILY_CAPACITY = 'daily_capacity',
}

export enum LOW_STOCK_MESSAGE_TYPE {
  FEW_ITEMS_LEFT = 'FEW_ITEMS_LEFT',
  LESS_THAN_MIN_LEFT = 'LESS_THAN_MIN_LEFT',
}
