import { useGlobalLoadingCart, useGlobalOrderTypeAndTime, usePersistedCartData } from 'common/hooks';
import { useUpdateCartItem } from 'service/hooks';
import { Cart } from 'service/types/generated';
import { handleScheduledTime } from 'modules/productsModule/utils';
import { updateCartItemVarsType } from 'service/hooks/cart/useUpdateCartItem';

type CartResponse = { hasError: boolean } | void | Partial<Cart>;
type UpdateCartItemType = (variables: updateCartItemVarsType) => Promise<CartResponse>;

type UseUpdateItemInCartType = () => UpdateCartItemType;

const useUpdateItemInCart: UseUpdateItemInCartType = () => {
  const [cartData] = usePersistedCartData();
  const [, setLoadingCart] = useGlobalLoadingCart();
  const [globalOrderTypeAndTime] = useGlobalOrderTypeAndTime();
  const { fulfillmentTimeType, scheduleSlotInterval } = globalOrderTypeAndTime || {};
  const scheduledTime = handleScheduledTime(fulfillmentTimeType, scheduleSlotInterval);

  const updateCartItem = useUpdateCartItem({ ...cartData });

  const updateItemInCart: UpdateCartItemType = async variables => {
    setLoadingCart(true);
    const response = await updateCartItem({ ...variables, scheduledTime });
    setLoadingCart(false);
    return response;
  };

  return updateItemInCart;
};

export default useUpdateItemInCart;
